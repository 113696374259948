import React, { useState, useCallback } from "react";
import SEO from "../seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import ReactPlayer from "react-player";
import "../../scss/pages/exhibition-page.scss";
import BackgroundGradient from "components/background-gradient";
import PlayButton from "../../images/play-button.inline.svg";
import { useEffect } from "react";
import { window, exists } from "browser-monads";
import ShareButton from "components/share-button";
import SocialIcon from "images/social-share-icon.inline.svg";
import { motion } from "framer-motion";

const rangeOfVideos = 5;

const ExhibitionVideoMessage = ({ data, pageContext }) => {
    const { locale } = pageContext;
    const { videoMessages, globalTexts } = data;

    exists(window);

    const [videoIndex, setVideoIndex] = useState(0);
    const [isMuted, setIsMuted] = useState(true);
    const [isFadedOut, setIsFadedOut] = useState(false);
    const [lastBreakerFrame, setLastBreakerFrame] = useState(0);
    const [exhibitionVideoData, setExhibitionVideoData] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        const getSingleMessage = async (id) => {
            try {
                const response = await fetch(
                    `https://europe-west1-superwe-api.cloudfunctions.net/app/messages/${id}`
                );
                const data = await response.json();
                return data;
            } catch (e) {
                console.warn("error", e);
            }
        };

        async function getVideoMetaData(randomizedListOfVideos) {
            const videoData = await Promise.all(
                randomizedListOfVideos.map(async ({ videoMessageId }) => {
                    const videoMeta = await getSingleMessage(videoMessageId);

                    return videoMeta;
                })
            );

            const exhibitionData = videoData.map(({ id, data }) => {
                return {
                    bgImageSource: `https://media.earthspeakr.art/video/upload/dl_0,f_jpg,q_auto,h_300/e_blur:800/messages/${id}.jpg`,
                    videoSource: data.media.mp4.secure_url,
                    png: data.media.png.secure_url,
                    videoMetadata: {
                        country: data.geo.country,
                        locality: data.geo.locality,
                    },
                    id,
                };
            });

            setExhibitionVideoData(exhibitionData);

            setIsloading(false);
        }

        if (exhibitionVideoData.length === 0) {
            const randomizedListOfVideos =
                videoMessages.nodes[0].listOfVideos.sort(
                    () => Math.random() - 0.5
                );
            getVideoMetaData(randomizedListOfVideos);
        }
    }, [exhibitionVideoData, videoMessages]);

    const isSecondBreakerFrame = videoIndex % (rangeOfVideos * 2) === 0;

    const breakerQuestion = isSecondBreakerFrame
        ? videoMessages.nodes[0].breakerQuestionTwo
        : videoMessages.nodes[0].breakerQuestion;

    const isBreakerFrame =
        videoIndex % rangeOfVideos === 0 && videoIndex !== lastBreakerFrame;

    const nextVideo = useCallback(() => {
        setIsFadedOut(true);
        setTimeout(() => {
            setVideoIndex(
                (prevIndex) => (prevIndex + 1) % exhibitionVideoData.length
            );
        }, 400);
    }, [exhibitionVideoData.length]);

    const handleMute = useCallback(() => {
        setIsMuted(false);
    }, []);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            setIsFadedOut(false);
        }, 50);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [videoIndex]);

    useEffect(() => {
        if (isBreakerFrame) {
            setIsFadedOut(true);
            const timeoutID = setTimeout(() => {
                setIsFadedOut(false);
                setLastBreakerFrame(videoIndex);
            }, 15000);
            return () => {
                clearTimeout(timeoutID);
            };
        }
    }, [isBreakerFrame, videoIndex]);

    const activeVideo = exhibitionVideoData[videoIndex];

    const videoProps = {
        playing: !isMuted && !isBreakerFrame,
        playsinline: true,
        url:
            activeVideo && activeVideo.videoSource
                ? activeVideo.videoSource
                : "",
        volume: 1,
        width: "100%",
        height: "100%",
        muted: isMuted,
        config: {
            attributes: {
                poster: activeVideo && activeVideo.png ? activeVideo.png : "",
            },
        },
    };

    const contentTransition = {
        exit: {
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeOut",
                duration: 1,
            },
        },

        enter: {
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 1,
            },
        },
    };

    const breakerTextTransition = {
        exit: {
            y: -60,
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeOut",
                duration: 1,
            },
        },
        enter: {
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: [0, 0.44, 0.68, 0.77],
                duration: 0.6,
            },
        },
    };

    return (
        <>
            <SEO title="Exhibition video message" />
            <Helmet>
                <body className="ExhibitionVideoMessage" />
            </Helmet>
            <div className="exhibitionPage">
                <motion.img
                    initial="exit"
                    exit="exit"
                    animate="enter"
                    variants={contentTransition}
                    src={
                        activeVideo && activeVideo.bgImageSource
                            ? activeVideo.bgImageSource
                            : ""
                    }
                    alt="bg"
                    className="bg-image"
                />
                {!isBreakerFrame && (
                    <motion.div
                        initial="exit"
                        exit="exit"
                        animate="enter"
                        variants={contentTransition}
                        className="message exhibition"
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <div
                            className={
                                isMuted
                                    ? "message__wrapper"
                                    : "message__wrapper no-cursor"
                            }
                        >
                            <div
                                className="message__video"
                                onClick={handleMute}
                            >
                                <motion.div
                                    animate={{ opacity: isFadedOut ? 0 : 1 }}
                                    className="message__video-container"
                                >
                                    <ReactPlayer
                                        {...videoProps}
                                        onEnded={nextVideo}
                                    />
                                    {isMuted && (
                                        <PlayButton className="play-button" />
                                    )}
                                </motion.div>
                            </div>
                            <motion.div
                                className="actions"
                                whileHover={{ y: -20 }}
                            >
                                <ShareButton
                                    postTitle="your post"
                                    siteTitle={`https://www.earthspeakr.art/${locale}`}
                                    locale={locale}
                                    globalTexts={globalTexts}
                                    shareUrl={`https://www.earthspeakr.art/${locale}/streaming`}
                                >
                                    <SocialIcon className="actions_social" />
                                    <p className="share-button__label">
                                        {globalTexts.shareLabel}
                                    </p>
                                </ShareButton>
                            </motion.div>
                        </div>
                        <div className="locations">
                            {activeVideo && (
                                <>
                                    <p>{activeVideo.videoMetadata?.locality}</p>
                                    <p> {activeVideo.videoMetadata?.country}</p>
                                </>
                            )}
                        </div>
                    </motion.div>
                )}
                {isBreakerFrame && (
                    <>
                        <motion.div
                            initial="exit"
                            exit="exit"
                            animate="enter"
                            variants={contentTransition}
                        >
                            <BackgroundGradient className="article" />
                        </motion.div>
                        <motion.div
                            initial="exit"
                            exit="exit"
                            animate="enter"
                            variants={breakerTextTransition}
                            className="breaker-question__container"
                        >
                            <p className="breaker-question">
                                {breakerQuestion}
                            </p>
                        </motion.div>
                    </>
                )}
                {isLoading && (
                    <>
                        <motion.div
                            initial="exit"
                            exit="exit"
                            animate="enter"
                            variants={contentTransition}
                        >
                            <BackgroundGradient className="article" />
                            <motion.div
                                initial="exit"
                                exit="exit"
                                animate="enter"
                                variants={breakerTextTransition}
                                className="breaker-question__container"
                            >
                                <p className="loading-text">Loading</p>
                            </motion.div>
                        </motion.div>
                    </>
                )}
            </div>
        </>
    );
};

export default ExhibitionVideoMessage;

export const query = graphql`
    query ExhibitionVideoMessageData($locale: String!) {
        videoMessages: allDatoCmsExhibitionPage(
            filter: { locale: { eq: $locale } }
        ) {
            nodes {
                listOfVideos {
                    videoMessageId
                }
                locale
                breakerQuestion
                breakerQuestionTwo
            }
        }
        globalTexts: datoCmsGlobalText(locale: { eq: $locale }) {
            copiedLinkLabel
            copyLinkLabel
            shareLabel
            mapLabel
            shareMessageTitle
            shareMessageText
            toolkitsMenuLabel
        }
    }
`;
